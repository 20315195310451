import React from 'react'; // For IE 9-11 support
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/ie9';
import App from './App';
import './index.css';
import './polyfill';
import * as serviceWorker from './serviceWorker';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import "react-tagsinput/react-tagsinput.css";
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
