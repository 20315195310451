export const EnviornmentType = {
  DEV: 'development',
  PROD: 'production',
};

export const env = process.env.NODE_ENV || EnviornmentType.DEV;
const data = {
  API_ENDPOINT:
    env === EnviornmentType.DEV
      ? 'http://localhost:7279/'
      : process.env.REACT_APP_LIVE_URL,

  API_VERSION: 'api',
  // API_VERSION: "api/v2",
};

data.DEFAULT_DATE_FORMAT = 'LLL';

export const AppConfig = data;
