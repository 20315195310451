import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

class NotFound extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="12" xs="12" lg="12">
              <div className="clearfix">
                <h1 className="display-1 text-center text-error">404!</h1>
                <h4 className="text-center">Sorry, The Page not found</h4>
                <p className="text-muted text-center mt-3">
                  We’re sorry, the domain you have looked for does not
                  registered with our AnalystPrep organisation!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default NotFound;
